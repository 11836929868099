/* eslint-disable react/forbid-prop-types */
import React from "react";
import { graphql } from "gatsby";
import tw from "twin.macro";

import Layout from "../components/Layout";
import PageWrapper from "../components/PageWrapper";
import ContentWrapper from "../components/ContentWrapper";
import SideWrapper from "../components/SideWrapper";
import SponsorSlider from "../components/SponsorSlider";
import InnerTitle from "../components/InnerTitle";
import Content from "../components/Content";
import Sidenavs from "../components/Sidenavs";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Date = tw.p`text-sm mb-6 mt-2 font-bold text-primary`;

const Post = ({ data }) => {
  const { seo, title, date, featuredImage } = data.wpPost;
  return (
    <Layout seo={seo}>
      <PageWrapper>
        <ContentWrapper>
          <InnerTitle tw="mb-0 max-w-[60rem]">{title}</InnerTitle>
          <Date>{date}</Date>
          <GatsbyImage image={getImage(featuredImage.node.localFile)} />
          <Content dangerouslySetInnerHTML={{ __html: data.wpPost.content }} />
        </ContentWrapper>
        <SideWrapper>
          <Sidenavs showCategories />
          <SponsorSlider sidebar />
        </SideWrapper>
      </PageWrapper>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!) {
    wpPost(id: { eq: $id }) {
      title
      date(formatString: "MMMM DD, YYYY")
      content
      featuredImage {
        ...FeaturedImage
      }
      ...PostSEO
    }
    allWpCategory(filter: { name: { nin: ["Uncategorized", "Español"] } }) {
      edges {
        node {
          label: name
          path: uri
        }
      }
    }
    relatedPosts: allWpPost(
      filter: { id: { ne: $id } }
      sort: { fields: date }
      limit: 5
    ) {
      nodes {
        uri
        title
      }
    }
  }
  fragment FeaturedImage on WpNodeWithFeaturedImageToMediaItemConnectionEdge {
    node {
      altText
      localFile {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 90)
        }
      }
    }
  }
`;
export default Post;
